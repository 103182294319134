<template>
  <setup-table
    :title="`Mechanic`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
    showSearch
    pagination
  >
    <template #itemForm v-if="showForm">
      <mechanic-form
        ref="mechanicForm"
        :sections="mechanicStructure"
        :value="editedItem"
        @submit="saveItem"
        @closeForm="closeForm"
        @formChanges="formChanges"
      ></mechanic-form>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_MECHANICS, SAVE_MECHANIC, DELETE_MECHANICS } from '@/store/modules/Mechanic/actions';
import SetupTable from './SetupTable.vue';
import MechanicForm from './MechanicForm.vue';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  name: 'MechanicSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, MechanicForm },
  data() {
    return {
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
      mechanicStructure: [],
      headers: [
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Email', value: 'email', sortable: true },
      ],
    };
  },
  computed: {
    ...mapGetters('mechanic', ['mechanicsList']),
  },
  mounted() {
    this.getItems();
  },
  methods: {
    ...mapActions('mechanic', [GET_MECHANICS, SAVE_MECHANIC, DELETE_MECHANICS]),
    async getItems() {
      this.loading = true;
      await this.getMechanics({ forTable: true });
      this.items = this.mechanicsList;
      this.loading = false;
    },
    async createItem() {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;
      this.editedItem = { name: '', email: '' };
      this.baseFormValue = { name: '', email: '' };
      this.latestFormValue = { name: '', email: '' };
      this.showForm = true;
    },
    async editItem(item) {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;
      this.editedItem = { ...item };
      this.baseFormValue = cloneDeep(this.editedItem);
      this.latestFormValue = cloneDeep(this.editedItem);
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const response = await this.saveMechanic(item);
        if (response && response.done) {
          this.$myalert.success('Mechanic saved');
          this.showForm = false;
          await this.getItems();
          this.editedItem = { ...item, id: response.id };
          this.baseFormValue = null;
          this.latestFormValue = null;
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Mechanics' : 'this Mechanic'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteMechanics(items.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${items.length > 1 ? 'Mechanics' : 'Mechanic'} deleted`);
          this.showForm = false;
          this.getItems();
        }
      }
    },
    closeForm() {
      this.showForm = false;
      this.baseFormValue = null;
      this.latestFormValue = null;
    },
    formChanges(value) {
      this.latestFormValue = cloneDeep(value);
    },
  },
  mixins: [promptBeforeLeave],
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
