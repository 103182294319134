var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("setup-table", {
    attrs: {
      title: `Mechanic`,
      items: _vm.items,
      headers: _vm.headers,
      loading: _vm.loading,
      clearItem: !_vm.showForm || !_vm.editedItem.id,
      showSearch: "",
      pagination: "",
    },
    on: {
      createItem: function ($event) {
        return _vm.createItem()
      },
      editItem: _vm.editItem,
      deleteItems: _vm.deleteItems,
    },
    scopedSlots: _vm._u(
      [
        _vm.showForm
          ? {
              key: "itemForm",
              fn: function () {
                return [
                  _c("mechanic-form", {
                    ref: "mechanicForm",
                    attrs: {
                      sections: _vm.mechanicStructure,
                      value: _vm.editedItem,
                    },
                    on: {
                      submit: _vm.saveItem,
                      closeForm: _vm.closeForm,
                      formChanges: _vm.formChanges,
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }