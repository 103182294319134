var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { attrs: { readonly: _vm.value.id > 0 && !_vm.canEditSetupTables } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "green", dark: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.value.name,
                          label: "Name",
                          required: "",
                          "error-messages": _vm.handleErrors(_vm.$v.form.name),
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.$v.form.name.$touch()
                          },
                        },
                        model: {
                          value: _vm.$v.form.name.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.form.name,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.form.name.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.value.email,
                          label: "Email",
                          required: "",
                          "error-messages": _vm.handleErrors(_vm.$v.form.email),
                          readonly: _vm.value.readonly,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.$v.form.email.$touch()
                          },
                        },
                        model: {
                          value: _vm.$v.form.email.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.form.email,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.form.email.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }